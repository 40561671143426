<template>
    <div class="page">
        <el-row :gutter="10">
          <el-col :span="showList ? 6 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
            <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
                 @click="showList = !showList"></div>
                <div class="bg-white" v-show="showList">
                    <div class="flex_b_c">
                        <el-descriptions title="栏目名称"></el-descriptions>
                    </div>
                    <el-input maxlength="66" prefix-icon="el-icon-search" @change="getColumn(1)"
                              v-model="columnSrh" clearable
                              placeholder="请输入栏目关键字" size="small"></el-input>
                    <el-table v-loading="columnLoading"
                              :data="columnList"
                              ref="columnTable"
                              size="small"
                              height="calc(100vh - 246px)"
                              class="table" highlight-current-row
                              @current-change="handleCurrentChange">
                        <el-table-column prop="columnSort" label="序号" width="50" show-overflow-tooltip/>
                        <el-table-column prop="columnName" label="栏目" show-overflow-tooltip/>
                        <el-table-column prop="columnShow" label="是否显示" v-show="hasPermission('homeSet:column:show')">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.columnShow" :active-value="0" :inactive-value="1"
                                           @change="isColumnChange(scope.row)"></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="60">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" icon="el-icon-edit"
                                           v-show="hasPermission('homeSet:column:edit')"
                                           @click="editColumn(scope.row)"></el-button>
                                <el-button style="display: none" size="mini" type="danger" plain icon="el-icon-delete"
                                           @click="delColumn(scope.row.id)"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
          <el-col :span="showList ? 18 : 24" :class="[showList ? '' : 'el-col-unfold','leftHeighe']">
                <el-form size="small" :inline="true" class="query-form" ref="searchForm" @submit.native.prevent @keyup.enter.native="getCollection(1)">
                    <el-form-item label="关键字：">
                        <el-input v-model.trim="collectionStr" placeholder="请输入名称关键字" clearable maxlength="100"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="getCollection(1)" size="small" icon="el-icon-search">查询</el-button>
                        <el-button @click="reset()" size="small" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
<!--                    <el-form-item class="f_r">-->
<!--                        <el-button type="primary" v-show="hasPermission('homeSet:list:add')"-->
<!--                                   @click="addCollection()" icon="el-icon-plus">新增</el-button>-->
<!--                        <el-button type="danger" icon="el-icon-delete" v-show="hasPermission('homeSet:list:del')" @click="delCollection()">批量删除-->
<!--                        </el-button>-->
<!--                    </el-form-item>-->
                </el-form>
                <div class="bg-white">
                    <div class="text_right">
                        <el-button size="small" icon="el-icon-remove-outline" @click="hideCollection()"
                                   v-show="hasPermission('homeSet:list:hide')">批量隐藏
                        </el-button>
                        <el-button size="small" icon="el-icon-view" @click="showCollection()"
                                   v-show="hasPermission('homeSet:list:show')">批量显示
                        </el-button>
                    </div>
                    <el-table
                            :data="collectionList"
                            v-loading="collectionLoading"
                            size="small"
                            height="calc(100vh - 328px)"
                            @selection-change="selectionChangeHandle"
                            class="table">
                        <el-table-column
                                type="selection"
                                header-align="center"
                                align="center"
                                width="50">
                        </el-table-column>
                        <el-table-column prop="exhibitionId" label="图片" width="110">
                            <template slot-scope="scope">
                                <el-image fit="contain" :src="scope.row.thumb_path" class="itemImg"
                                          style="width: 100px; height: 100px;">
                                    <el-image slot="placeholder" fit="contain" class="itemImg"
                                              :src="require('@/assets/img/thumbnail.png')" style="width: 100px; height: 100px"></el-image>
                                    <el-image slot="error" fit="contain" class="itemImg"
                                              :src="require('@/assets/img/thumbnail.png')" style="width: 100px; height: 100px"></el-image>
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip prop="collection_num" label="藏品编号" width="120"
                                         sortable></el-table-column>
                        <el-table-column show-overflow-tooltip prop="collection_name" label="藏品名称" width="120"></el-table-column>
                        <el-table-column show-overflow-tooltip prop="resource_name" label="资源名称" width="120"></el-table-column>
                        <el-table-column
                                prop="name"
                                label="是否显示" width="120">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.is_show" :active-value="0" :inactive-value="1"
                                           @change="isCollectionChange(scope.row)"></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="sort"
                                label="排序号" sortable show-overflow-tooltip width="120">
                        </el-table-column>
                        <el-table-column
                                prop="column_name"
                                label="类别" sortable show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                                fixed="right"
                                width="150"
                                label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" v-show="hasPermission('home:details:view')" @click="view(scope.row)">详情</el-button>
                                <el-button type="text" size="small" v-show="hasPermission('homeSet:list:edit')" @click="editCollection(scope.row)">修改</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="collectionSizeChangeHandle"
                            @current-change="collectionCurrentChangeHandle"
                            :current-page="collectionPageNo"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="collectionPageSize"
                            :total="collectionTotal"
                            background
                            layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <!--新增、编辑栏目-->
        <AddColumn ref="addColumn" @refreshDataList="getColumn"></AddColumn>
        <!--编辑藏品-->
        <EditCollection ref="editCollection" @refreshDataList="getCollection"></EditCollection>
        <!--新增藏品-->
        <AddCollection ref="addCollection" @refreshDataList="getCollection"></AddCollection>
    </div>
</template>

<script>
    import AddColumn from './addColumn' // 新增栏目
    import EditCollection from './editCollection' // 编辑藏品
    import AddCollection from './addCollection' // 新增藏品

    export default {
        components: {AddColumn, EditCollection, AddCollection},
        data() {
            return {
                // 栏目
              showList:true,
                columnSrh: '',
                columnLoading: false,
                columnList: [],
                columeId: '',
                // 藏品
                collectionStr: '',
                dataListSelections: [],
                collectionLoading: false,
                collectionList: [],
                collectionPageNo: 1,
                collectionPageSize: 10,
                collectionTotal: 0,
            }
        },
        created() {
            let homeSetOpt = JSON.parse(sessionStorage.getItem('homeSetOpt'))
            if(homeSetOpt) {
                this.columeId = homeSetOpt.columeId
                this.collectionPageNo = parseInt(homeSetOpt.collectionPageNo);
                this.collectionPageSize = parseInt(homeSetOpt.collectionPageSize)
            }
        },
        mounted() {
            this.getColumn()
            this.getCollection()
        },
        methods: {
            // 藏品列表数据
            getCollection(type) {
                if(type == 1) {
                    this.collectionPageNo = 1
                }
                this.collectionLoading = true
                this.$axios(this.api.dyyg.homeDetailsList, {
                    'size': this.collectionPageSize,
                    'current': this.collectionPageNo,
                    'name': this.collectionStr,
                    'columeId': this.columeId
                }, "get").then((res) => {
                    if (res.status) {
                        this.collectionList = res.data.records
                        this.collectionTotal = parseInt(res.data.total);
                        this.collectionLoading = false
                    }
                })
            },
            // 重置
            reset() {
                this.collectionStr = ''
                this.getCollection(1);
            },
            // 查看
            view(row) {
                let opt = {
                    columeId: this.columeId,
                    collectionPageNo: this.collectionPageNo,
                    collectionPageSize: this.collectionPageSize,
                }
                sessionStorage.setItem('homeSetOpt', JSON.stringify(opt))
                var newRow = row
                newRow.id = newRow.foreign_key_id
                sessionStorage.setItem('dizhi','/dyyg/home/homeSetting')
                this.$router.push({
                    path: '/dyyg/home/retrievedDetails',
                    query: newRow
                });
            },
            // 新增藏品
            addCollection() {
                this.$refs.addCollection.init()
            },
            // 编辑藏品
            editCollection(row) {
                this.$refs.editCollection.init('edit', row)
            },
            // 批量删除藏品
            delCollection() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.dyyg.updateShowByIds, {'ids': ids, 'type': 2}, 'post').then(data => {
                        if (data && data.status) {
                            this.$message.success('操作成功')
                            this.getCollection(1)
                        } else {
                            this.$message.error('操作失败')
                        }
                    })
                })
            },
            // 批量隐藏藏品
            hideCollection() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定隐藏所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.dyyg.updateShowByIds, {'ids': ids, 'type': 1}, 'post').then(data => {
                        if (data && data.status) {
                            this.$message.success('隐藏成功')
                            this.getCollection()
                        } else {
                            this.$message.error('隐藏失败')
                        }
                    })
                })
            },

            // 批量显示藏品
            showCollection() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定显示所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.dyyg.updateShowByIds, {'ids': ids, 'type': 0}, 'post').then(data => {
                        if (data && data.status) {
                            this.$message.success('显示成功')
                        } else {
                            this.$message.error('显示失败')
                        }
                        this.getCollection()
                    })
                })
            }
            ,
            // 藏品多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            }
            ,
            // 是否显示藏品
            isCollectionChange(row) {
                this.collectionLoading = true
                this.$axios(this.api.dyyg.updateHomeDetails, {
                    'id': row.id,
                    'isShow': row.is_show
                }, "post").then((res) => {
                    if (res.status) {
                        this.$message.success('操作成功')
                    } else {
                        this.$message.error('操作失败')
                    }
                    this.collectionLoading = false
                    this.getCollection()
                })
            }
            ,
            // 栏目查询
            getColumn() {
                this.columnLoading = true
                this.$axios(this.api.dyyg.homeColumn, {
                    'size': 20,
                    'current': 1,
                    'columnName': this.columnSrh
                }, "get").then((res) => {
                    if (res.status) {
                        this.columnList = res.data.records
                        this.columnLoading = false
                    }
                })
            },
            // 添加栏目
            addColumn() {
                this.$refs.addColumn.init("add")
            }
            ,
            // 编辑栏目
            editColumn(row) {
                this.$refs.addColumn.init("edit", row)
            }
            ,
            // 点击栏目
            handleCurrentChange(val) {
                this.collectionPageNo = 1
                this.columeId = val.id
                //this.$refs.columnTable.setCurrentRow(val);
                this.getCollection()
            }
            ,
            // 是否显示栏目（单个）
            isColumnChange(row) {
                this.columnLoading = true
                this.$axios(this.api.dyyg.updateHomeColumn, {
                    'id': row.id,
                    'columnShow': row.columnShow
                }, "post").then((res) => {
                    if (res.status) {
                        this.$message.success('操作成功')
                    } else {
                        this.$message.error('操作失败')
                    }
                    this.columnLoading = false
                    this.getColumn()
                })
            }
            ,
            // 删除栏目
            delColumn(id) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.zlzs.delete, {'ids': id}, 'delete').then(data => {
                        if (data && data.status) {
                            this.$message.success('删除成功')
                            this.refreshList()
                        } else {
                            this.$message.error('删除失败')
                        }
                    })
                })
            }
            ,
            // 藏品每页数
            collectionSizeChangeHandle(val) {
                this.collectionPageSize = val;
                this.collectionPageNo = 1;
                this.getCollection();
            }
            ,
            // 藏品当前页
            collectionCurrentChangeHandle(val) {
                this.collectionPageNo = val;
                this.getCollection();
            }
        },
    }
</script>

<style scoped>

</style>
